
<template>
    <div class="d-flex flex-column w-50">
        <div class="card battle-card text-start" :style="`min-height: ${minHeight}px; max-height: ${maxHeight}px; overflow-y: auto`">
            <div class="p-3 d-flex flex-row align-items-center justify-content-between h-100" v-bind:class="{'justify-content-around': !reward}">
                <div class="text-center" v-if="!reward">
                    <span class="fw-bold text-white">Reward Detail</span><br>
                    <span class="text-white">Select notification to see the details</span>
                </div>
                <div class="text-start text-white" v-else>
                    <span class="fw-bold">Reward Detail</span><br>
                    <span class="label-text fw-bold me-1">Challenge</span> <span class="fw-bold">{{ reward.challenge.title }}</span> <span class="level-info">Level {{reward.challenge.level}}</span><br>
                    <span class="label-text fw-bold me-1">Participant Name</span> <span class="fw-bold">{{`${reward.user.metadata.firstName} ${reward.user.metadata.lastName}`}}</span><br>
                    <span class="label-text fw-bold me-1">Status</span> <span class="fw-bold">Completed</span><br>
                    <span class="label-text fw-bold me-1">Reward</span> <span class="fw-bold">$50</span><br>
                </div>
                <div class="d-flex flex-column">
                <img width="124" height="107" src="@/assets/rewards.svg">
                    <action-button class="mt-2" @click="handlePaymentClick" textContent="Pay Now"></action-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import {useNhostClient} from "@nhost/vue";
import {getCompletedChallenges} from "@/helpers/challengesHelpers";
import ActionButton from "@/components/ActionButton";

defineProps({
    reward: {type: Object, default: () => {return null}},
    minHeight: {type: Number, default: 180},
    maxHeight: {type: Number, default: 400}
})

const {nhost} = useNhostClient();
const completedChallenges = ref([]);

const updateChalengesActivity = async () => {
    completedChallenges.value = await getCompletedChallenges(nhost);
}

const handlePaymentClick = () => {
    alert('Pending payment integration');
}

onMounted(() => {
    updateChalengesActivity();
})
</script>
<style scoped>
th {
    background: #0B1B18;
}

tr td {
    padding: 8px;
}

tr {
    border-bottom: 0.4px solid #5a5a5a;
}

.level-info {
    display: inline-flex;
    margin-top: 2px;
    padding: 4px 6px;
    border-radius: 4px;
    background: #133D35;
    max-width: fit-content;
}
</style>