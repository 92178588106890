<template>
    <div class="challenge-container d-flex justify-content-center">
        <div class="w-100 z-2 d-flex flex-column justify-content-between">
            <TopProfileBar/>
<!--            <div class="ps-4 text-start"> <a href="#" @click="$router.push('/home')">Home</a> > <b>-->
<!--                <span class="text-white">-->
<!--                    Challenge Details-->
<!--                </span>-->
<!--            </b></div>-->
            <div v-if="completeChallenge" class="d-flex justify-content-between align-items-center m-4 challenge-details">
                <div class="home-description-container text-start">
                    <span class="title">{{ completeChallenge.title }}</span> <br>
                    <span class="subtitle text-white">{{completeChallenge.description}}</span>
                </div>
                <div v-if="completeChallenge?.characterImageUrl">
                    <img class="character" :src="completeChallenge.characterImageUrl" width="290" height="290">
                </div>
            </div>
            <div class="cards-container">
                <StartChallenge
                    v-if="challengeTaskList.length === 0 && completeChallenge"
                    :challenge-tasks="completeChallenge.challenge_tasks"
                    :challenge-id="challengeId"
                    @startChallenge="getChallengeTasks"
                />
                <FinishChallengeTasks
                    v-else
                    @updateTasks="getChallengeTasks"
                    :challenge-task-list="challengeTaskList"
                    :completeChallenge="completeChallenge"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import {useNhostClient, useUserData} from '@nhost/vue'
import { onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {getCompleteChallengeById, getUserChallengeTasks} from "@/helpers/challengesHelpers";
import {getImageFileFromStorage} from "@/helpers/fileHelpers";
import TopProfileBar from "@/components/TopProfileBar";
import StartChallenge from "@/components/StartChallenge";
import FinishChallengeTasks from "@/components/FinishChallengeTasks";

const { nhost } = useNhostClient()
const challengeId = ref(null);
const completeChallenge = ref(null);
const route = useRoute()
const user = useUserData();
const challengeTaskList = ref([]);

onMounted(async () => {
    await nhost.auth.refreshSession();
    challengeId.value = route.params.id;
    await getCompleteChallenge();
    await getChallengeTasks();
})

const getCompleteChallenge = async () => {
    let result = await getCompleteChallengeById(challengeId.value, nhost);
    if (result && !result?.characterImageUrl) {
        result.characterImageUrl = await getImageFileFromStorage(nhost, result?.character_image_id)
    }
    completeChallenge.value = result;
}

const getChallengeTasks = async () => {
    challengeTaskList.value = await getUserChallengeTasks(user.value.id, challengeId.value, nhost);
}

</script>

<style scoped>

.title {
    color: #FFF;
    /* H2/medium */
    font-family: 'Roboto';
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
}

.subtitle {
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
}

.challenge-container {
    overflow: auto;
    background-image: url("/src/assets/bg.jpg");
    width: 100vw;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Inter';
}

th {
    background: #0B1B18;
}

tr td {
    padding: 8px;
}

tr {
    border-bottom: 0.4px solid #2B2B52;
}

@media only screen and (max-width: 600px) {
    .challenge-container {
        background-position: center;
    }

    .character {
        width: 150px;
        height: 150px;
    }

    .challenge-details {
        flex-direction: column!important;
        align-items: flex-start!important;
    }

    .title {
        font-size: 20px!important;
    }
}
</style>