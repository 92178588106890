import { createApp } from 'vue'
import { createApolloClient } from '@nhost/apollo'
import { NhostClient } from '@nhost/vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import router from "@/router";

import App from './App.vue'

// NHOST Setup
const subdomain = process.env.VUE_APP_NHOST_SUBDOMAIN;
const region = process.env.VUE_APP_NHOST_REGION;
const nhost = new NhostClient({
    subdomain,
    region
})

router.beforeEach(async (to) => {
    document.title = to.meta.title || 'Battle of creators';
    if (!['/login', '/resetPassword', '/signup'].includes(to.path) && !(await nhost.auth.isAuthenticatedAsync())) {
        return '/login'
    }
    return true
})

const app = createApp(App);
const apolloClient = createApolloClient({ nhost })

app.use(router);
app.provide(DefaultApolloClient, apolloClient);
app.use(nhost);
app.mount('#app')

