<template>
    <div class="d-flex signup-container align-items-center">
        <div class="signup-content">
            <div class="row text-start mb-2 text-white text-center">
                <div class="d-flex justify-content-center align-items-center">
                    <SmallLogo/>
                </div>
                <div class="mt-3">
                    <h4>Reset Password</h4>
                    <span>Enter your new password and continue</span>
                </div>
                <br>
            </div>
            <div v-if="!needsEmailVerification">
                <div class="row">
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                    <PasswordInputLogin
                        v-model="user.password"
                        placeholder="Enter your password"
                    />
                    <PasswordInputLogin
                        v-model="user.confirm_password"
                        placeholder="Confirm password"
                    />
                </div>
                <div class="row mt-3">
                    <ActionButton @click="handleSubmit" text-content="Reset Password"/>
                </div>
            </div>
            <div class="text-start" v-else>
                <p class="text-success">
                    Please check your mailbox and follow the verification link to verify your email.
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import ActionButton from "@/components/ActionButton";
import {useNhostClient, useSignUpEmailPassword} from '@nhost/vue'
import {onMounted, ref} from "vue";
import PasswordInputLogin from "@/components/PasswordInputLogin";
import router from "@/router";
import SmallLogo from "@/components/SmallLogo";

const { needsEmailVerification } = useSignUpEmailPassword()
const {nhost}= useNhostClient();

const user = ref({
    firstName: '',
    lastName: '',
    email: '',
    confirm_password: '',
    password: '',
    termsAccepted: false,
    anotherCheckbox: false
});

const errorMessage = ref('');

const handleSubmit = async (event) => {
    event.preventDefault()
    errorMessage.value = "";

    let {password, confirm_password} = user.value;
    if (!password || !confirm_password) {
        errorMessage.value = 'You need to create a password and confirm it.';
        return;
    } else if(password !== confirm_password) {
        errorMessage.value = "The password confirmation does not match the provided password.";
        return;
    }

    const {error} = await nhost.auth.changePassword({ newPassword: password });
    if (!error) {
        router.push('/home');
    }
}

onMounted(() => {
    const isAuthenticated = nhost.auth.isAuthenticated();
    console.log('IS AUTH:', isAuthenticated);
    if (!isAuthenticated) {
        // router.push('/login');
    }
})

</script>

<style>
.signup-container {
    background-image: url("/src/assets/bg.jpg");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.signup-content {
    max-width: 400px;
    margin-left: 100px;
}
</style>