<template>
    <div class="d-flex mt-3">
        <FileUpload @filesUploaded="handleFilesUploaded" v-if="task?.challenge_task?.action?.solve === 'file_upload'"></FileUpload>
        <LinkGenerator class="ms-4" v-if="task?.challenge_task?.action?.type"/>
    </div>
</template>

<script setup>
import {defineProps, defineEmits} from "vue";
import FileUpload from "@/components/FileUpload";
import LinkGenerator from "@/components/LinkGenerator";

const props = defineProps({
    task: { type: Object, default: () => {return {}} }
});

const emit = defineEmits(['filesUploaded']);

const handleFilesUploaded = (files) => {
    emit('filesUploaded', files, props.task?.task_id);
}

</script>

<style scoped>
</style>
