<template>
    <div class="d-flex flex-column w-50">
        <div class="card battle-card text-start" :style="`min-height: ${minHeight}px; max-height: ${maxHeight}px; overflow-y: auto`">
            <span class="fw-bold text-white leaders-card subtitle ps-4 pb-2 pt-2">Manage Rewards</span>
            <table class="text-white w-100">
                <tr
                    class="ps-4 pe-4"
                    v-for="(user_challenge, index) in completedChallenges"
                    v-bind:class="{'selected': user_challenge.selected}"
                    :key="index"
                    @click="selectChallenge(index)"
                >
                    <td class="ps-4 pe-4">
                        <i class="bi bi-circle-fill text-primary me-1" style="font-size: 10px"></i>
                        <span class="text-primary">
                            {{user_challenge.user.metadata.firstName}} {{user_challenge.user.metadata.lastName}}
                        </span>
                        Completed Level {{user_challenge.challenge.level}} Challenge
                        <span class="text-primary">{{user_challenge.challenge.title}}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script setup>
import {defineProps, onMounted, ref, defineEmits} from "vue";
import {useNhostClient} from "@nhost/vue";
import {getCompletedChallenges} from "@/helpers/challengesHelpers";

const emit = defineEmits(['selectChallenge']);

defineProps({
    minHeight: {type: Number, default: 180},
    maxHeight: {type: Number, default: 400}
})

const {nhost} = useNhostClient();
const completedChallenges = ref([]);

const updateChalengesActivity = async () => {
    completedChallenges.value = await getCompletedChallenges(nhost);
}

const selectChallenge = (index) => {
    completedChallenges.value.every((item) => delete item?.selected);
    completedChallenges.value[index].selected = true;
    emit('selectChallenge', completedChallenges.value[index]);
}

onMounted(() => {
    updateChalengesActivity();
})
</script>
<style scoped>
th {
    background: #0B1B18;
}

tr td {
    padding: 8px;
}

tr {
    border-bottom: 0.4px solid #5a5a5a;
    cursor: pointer;
}

.selected {
    background-color: #0B1B18;
}
</style>