<template>
    <div class="top-profile-container d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex">
            <span class="ms-3 logo-container">
                <SmallLogo :logoSize="40" textSize="-25"/>
            </span>
            <span class="ms-4 d-flex align-items-center" v-if="isAuthenticated">
                <i class="bi bi-circle-fill text-primary" style="font-size: 10px"></i>
                <span class="ms-2 welcome-message">Hi {{user.metadata?.firstName}}, we hope you are doing well! 😃</span>
            </span>
        </div>
        <div>
            <div class="profile-image-container" v-if="isAuthenticated" @click="showMainMenu = !showMainMenu">
                        <span class="me-2">
                            {{user?.metadata?.firstName}} {{user?.metadata?.lastName}}
                        </span>
                <img height="40" width="40" class="rounded-5 p-1" v-show="avatarImage" :src="avatarImage"/>
            </div>
            <div v-if="showMainMenu" class="profile-menu">
                <ul>
                    <li @click="$router.push('/home')">Home</li>
                    <li @click="$router.push('/leaderboards')">Leaderboard</li>
                    <li @click="$router.push('/dashboard')">Dashboard</li>
                    <li @click="$router.push('/admin_page')">Manage</li>
                    <li @click="$router.push('/profile')">Settings</li>
                    <li @click="logout">Logout</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script setup>
import {useAuthenticated, useSignOut, useUserData} from "@nhost/vue";
import SmallLogo from "@/components/SmallLogo"
import {computed, ref} from "vue";
import {getAvatarImage} from "@/helpers/profileHelpers";
import router from "@/router";
const isAuthenticated = useAuthenticated();
const { signOut } = useSignOut();

const avatarImage = computed(() => {
    return getAvatarImage(user?.value?.avatarUrl);
})
const user = useUserData();

const showMainMenu = ref(false);

const logout = () => {
    signOut();
    router.push('/login')
}

</script>
<style scoped>
.top-profile-container {
    margin: 20px;
    font-family: 'Inter';
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(16px);
    color: #2F2928;
    padding: 5px;
    z-index: 1;
}

.profile-image-container {
    cursor: pointer;
}

.profile-menu {
    position: absolute;
    text-align: left;
    min-width: 120px;
    font-weight: bold;
    margin-top: 10px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.90);
}

.profile-menu ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.profile-menu ul li {
    cursor: pointer;
    margin: 2px;
    padding: 5px;
}

.profile-menu ul li:hover {
    background-color: white;
}

@media only screen and (max-width: 600px) {
    .logo-container {
        display: none;
    }

    .welcome-message {
        font-size: 10px;
    }
}
</style>