<template>
    <div class="d-flex justify-content-center">
        <div class="w-50">
            <div class="row text-start">
                <span class="text-center fs-5"><b>Enter confirmation code</b></span>
                <span class="text-center text-secondary">
                    A 4-digit code was sent to {{email}}
                </span>
                <br>
            </div>
            <div class="d-flex d-inline-block justify-content-center mt-4 mb-5">
                <input
                    v-model.number="code[0]"
                    v-on:keydown="event => handleNext(event, 1)"
                    maxlength="1"
                    class="form-control"
                    ref="code0"
                />
                <input
                    v-model.number="code[1]"
                    v-on:keydown="event => handleNext(event, 2)"
                    maxlength="1"
                    class="form-control"
                    ref="code1"
                />
                <input
                    v-model.number="code[2]"
                    v-on:keydown="event => handleNext(event, 3)"
                    maxlength="1"
                    class="form-control"
                    ref="code2"
                />
                <input
                    v-model.number="code[3]"
                    v-on:keydown="event => handleNext(event, 4)"
                    maxlength="1"
                    class="form-control"
                    ref="code3"
                />
            </div>
            <div>
                <a href="#">Resend code</a>
            </div>
            <div class="row mt-3">
                <ActionButton text-content="Continue"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import ActionButton from "@/components/ActionButton";
import {ref, watchEffect} from "vue";

const code  = ref([]);
const email = ref('');
const code0 = ref(null);
const code1 = ref(null);
const code2 = ref(null);
const code3 = ref(null);


const handleNext = (event, refCode) => {
    setTimeout(() => {
        if (!/^[0-9]$/.test(event.key) && event.key !== 'Backspace') {
            eval(`code${refCode-1}`).value.value = "";
            code.value[refCode-1] = "";
            return;
        }

        if (event.key == 'Backspace') {
            refCode = Math.max(0, refCode - 2);
        }
        refCode = Math.min(refCode, 3);
        let refKey = `code${refCode}`;
        eval(refKey).value.focus();
    }, 100);
}

watchEffect((() => {
    console.log(code.value);
}))

</script>

<style scoped>
input {
    width: 45px;
    height: 45px;
    text-align: center;
    margin: 3px;
}
</style>