<template>
    <div class="ms-4 me-4 mb-4 d-flex text-white finish-container">
        <div class="card battle-card challenge-details-card w-50 h-50 p-2">
            <div class="text-start">
                <div v-if="completeChallenge" class="d-flex align-content-center justify-content-between">
                    <div>
                        <div class="text-primary fw-bold">Progress</div>
                        <div class="text-white">{{completedTasks}}/{{tasks.length}}</div>
                    </div>
                    <div class="coin-icon">
                        <img width="20" height="20" src="@/assets/gold_coins.png">
                        {{completeChallenge.points}} Points
                    </div>
                </div>
                <ProgressBar :value="tasksProgressCount"/>
            </div>
            <ul v-if="challengeTaskList" class="custom-list">
                <li v-for="(item, index) in challengeTaskList" :key="index" @click="selectTask(item)">
                    <div class="d-flex justify-content-between">
                        <div>{{item.challenge_task.title}}</div>
                        <div>
                            <img v-if="item.completed" src="@/assets/check.png">
                            <img v-else src="@/assets/bg.png">
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="card battle-card challenge-details-card ms-2 w-50 h-50 text-white p-2">
            <div v-if="!selectedTask" class="empty-state d-flex flex-column align-items-center">
                <img src="@/assets/square_dot.png" width="50" height="40">
                <span class="p-2 fw-bold">No Task Selected</span>
                <span>Select any task to see the details here.</span>
            </div>
            <div v-else class="text-start p-2">
                <div>
                    <div class="fw-bold">{{selectedTask.challenge_task.title}}</div>
                    {{selectedTask.challenge_task.description}}
                </div>
                <TaskAction @filesUploaded="handleFilesUploaded" :task="selectedTask"/>
            </div>
        </div>
    </div>
</template>
<script setup>
import {computed, defineProps, ref, defineEmits, watchEffect} from "vue";
import ProgressBar from "@/components/ProgressBar";
import TaskAction from "@/components/TaskAction";
import {finishChallengeAndGivePoints, finishTask} from "@/helpers/challengesHelpers";
import {useNhostClient, useUserData} from "@nhost/vue";

const { nhost } = useNhostClient();
const user = useUserData();

const props = defineProps({
    completeChallenge: { type: Object, default: () => {return {}} },
    challengeTaskList: { type: Array, default: () => {return []} },
})


const tasks = computed(() => props.challengeTaskList ?? []);
const completedTasks = computed(() => tasks.value.filter((item) => item.completed).length ?? 0)
const selectedTask = ref(null);
const emit = defineEmits(['updateTasks'])

const tasksProgressCount = computed(() => {
    const total = (tasks.value?.length ?? 0);
    return (completedTasks.value * 100) / total;
})

const selectTask = (task) => {
    selectedTask.value = task;
}

// Finish task
const handleFilesUploaded = async (files, taskId) => {
    await finishTask(taskId, JSON.stringify(files), nhost);
    emit('updateTasks');
}

watchEffect(() => {
    if (tasksProgressCount.value === 100 && !props.completeChallenge?.completed) {
        let {id, points} = props.completeChallenge;
        finishChallengeAndGivePoints(user.value.id, id, points, nhost);
    }
})

</script>

<style scoped>
.challenge-details-card {
    min-height: 220px;
}

.coin-icon {
    color: white;
    background-color: #133D35;
    height: fit-content;
    padding: 3px;
    border-radius: 5px;
}

.custom-list {
    margin-top: 10px;
    padding:0px;
    list-style: none;
    text-align: left;
    color: #E1E1E1;
}

.custom-list li {
    border-bottom: solid 1px #22433d;
    padding: 4px;
    cursor: pointer;
    border-radius: 5px;
}

.custom-list li:hover {
    background-color: #1d413a;
}

@media only screen and (max-width: 600px) {
    .finish-container {
        flex-direction: column!important;
        margin: 10px!important;
    }

    .battle-card {
        width: 100%!important;
    }

    .challenge-details-card {
        margin: 0px!important;
        margin-top: 5px!important;
    }
}
</style>