<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import '/custom.scss';

@font-face {
    font-family: 'Roboto';
    src: url('@/assets/fonts/Roboto-Regular.ttf')
}

@font-face {
    font-family: 'Inter';
    src: url('@/assets/fonts/Inter-VariableFont.ttf')
}

@font-face {
    font-family: 'Poppins';
    src: url('@/assets/fonts/Poppins-Regular.ttf')
}

#app {
    font-family: 'Roboto', Arial;
    text-align: center;
    color: #2c3e50;
    font-size: 14px;
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
}

.title {
    color: #FFF;
    /* H2/medium */
    font-family: 'Roboto';
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
}

.subtitle {
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
}

a {
    text-decoration: none;
    font-weight: normal!important;
    color: white!important;
}

body {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
}

.battle-circle {
    color: #3360E7;
}

.battle-card {
    border-radius: 16px;
    border: 1px solid #0C4A3D;
    background: radial-gradient(128.15% 128.15% at 61.18% 108.7%, #0B1B18 0%, #112E28 100%, #112E28 100%);
    overflow: hidden;
}

/* WebKit (Chrome, Safari) */
::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1); /* Set the color of the thumb */
    border-radius: 4px; /* Optional: Round the corners of the thumb */
}

/* Firefox */
body {
    scrollbar-width: thin; /* Set the width of the scrollbar */
}

body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1); /* Set the color of the thumb */
}

.challenge-details-card {
    min-height: 220px;
}

.coin-icon {
    color: white;
    background-color: #133D35;
    height: fit-content;
    padding: 3px;
    border-radius: 5px;
}

.custom-list {
    margin-top: 10px;
    padding:0px;
    list-style: none;
    text-align: left;
    color: #E1E1E1;
}

.custom-list li {
    border-bottom: solid 1px #22433d;
    padding: 4px;
    cursor: pointer;
    border-radius: 5px;
}

.custom-list li:hover {
    background-color: #1d413a;
}

.btn-secondary {
    padding: 0px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    font-weight: bold;
}

.label-text {
    color: #BFBFBF;
}
</style>
