<template>
    <div class="file-upload-container" @click="$refs.fileInput.click()">
        <img width="100" height="100" src="@/assets/upload_icon.png">
        <input ref="fileInput" class="d-none" type="file" @change="handleFileChange" multiple/>
        <div class="text-center">Upload</div>
    </div>
</template>

<script setup>
import {defineProps, ref, defineEmits} from "vue";
import {useNhostClient} from "@nhost/vue";

const {nhost} = useNhostClient();
const emit = defineEmits(['filesUploaded'])

defineProps({
    task: { type: Object, default: () => {return {}} }
});

const fileInput = ref(null);

const handleFileChange = async (event) => {
    let fileIds = [];
    for (let file of event.target.files) {
        let {fileMetadata} = await nhost.storage.upload({file, bucketId: 'tasks'});
        fileIds.push(fileMetadata.id);
    }
    emit("filesUploaded", fileIds);
}

</script>
<style lang="scss" scoped>
.file-upload-container {
    cursor: pointer;
}
</style>

<style scoped>
</style>
