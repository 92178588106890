<template>
    <div class="d-flex flex-column">
        <div class="card battle-card text-start" :style="`min-height: ${minHeight}px; max-height: ${maxHeight}px; overflow-y: auto`">
            <span class="fw-bold text-white leaders-card subtitle ps-4 pb-2 pt-2">Challenge Updates</span>
            <table class="text-white w-100">
                <tr class="ps-4 pe-4" v-for="(task, index) in activity" :key="index">
                    <td class="ps-4 pe-4">
                        <i class="bi bi-circle-fill text-primary me-1" style="font-size: 10px"></i>
                        <span class="text-primary">
                            {{task.user.metadata.firstName}} {{task.user.metadata.lastName}}
                        </span>
                        Shared
                        <span class="text-primary">{{JSON.parse(task?.solved_data)?.length ?? 0}} images</span>
                        <span> of {{task.challenge_task.title}} Task for </span>
                        <span class="text-primary">{{task.user.user_challenge.challenge.title}}</span>
                        Challenge
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import {useNhostClient} from "@nhost/vue";
import {getChallengesActivity} from "@/helpers/challengesHelpers";

defineProps({
    minHeight: {type: Number, default: 250},
    maxHeight: {type: Number, default: 400}
})

const {nhost} = useNhostClient();
const activity = ref([]);

const updateChalengesActivity = async () => {
    activity.value = await getChallengesActivity(nhost);
}

onMounted(() => {
    updateChalengesActivity();
})
</script>
<style scoped>
th {
    background: #0B1B18;
}

tr td {
    padding: 8px;
}

tr {
    border-bottom: 0.4px solid #5a5a5a;
}
</style>