<template>
    <div class="modal fade show" style="display: block;" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content bg-secondary text-white">
                <div class="text-center mt-4">
                    <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
                </div>
                <div class="modal-body">
                    <div v-for="(file, index) in files" :key="index">
                        <div><span class="label-text">Task: </span> {{file.title}} </div>
                        <div class="bg-white" v-if="fileBeingDisplayed === index">
                            <img width="200" height="300" :src="file.fileUrl">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="emit('close')" type="button" class="btn btn-primary">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, onMounted} from "vue";

const emit = defineEmits(['close']);
const fileBeingDisplayed = ref(null);

defineProps({
    files: Array,
    title: { type: String, default: 'Modal title'}
});

onMounted(() => {
    if (!fileBeingDisplayed.value) {
        fileBeingDisplayed.value = 0;
    }
})

</script>

<style scoped>
.btn-text {
    font-size: 14px;
}
</style>
