<template>
    <div class="d-flex signup-container align-items-center">
        <div class="signup-content">
            <div class="row text-start mb-2 text-white text-center">
                <div class="d-flex justify-content-center align-items-center">
                    <SmallLogo/>
                </div>
                <div class="mt-3">
                    <h4>Forgot Password</h4>
                    <span>Enter your email and we will send you a link to reset the password!</span>
                </div>
                <br>
            </div>
            <div>
                <div class="row">
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                    <EmailLoginInput
                        v-model="user.email"
                        placeholder="name@email.com"
                    />
                </div>
                <div class="row mt-3">
                    <ActionButton @click="handleSubmit" text-content="Send Link"/>
                </div>
                <div class="row mt-2">
                    <div>
                        <span class="text-secondary">Do you know your password? </span>
                        <a
                            class="link-underline link-underline-opacity-0"
                            href="#"
                            @click="router.push('/login')"
                        >Log in</a>
                    </div>
                </div>
            </div>
            <div class="text-start" v-if="successMessage">
                <p class="text-success">
                    {{successMessage}}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import ActionButton from "@/components/ActionButton";
import {useNhostClient, useSignOut} from '@nhost/vue'
import {onMounted, ref} from "vue";
import router from "@/router";
import EmailLoginInput from "@/components/EmailLoginInput";
import SmallLogo from "@/components/SmallLogo";

const { signOut } = useSignOut()
const {nhost} = useNhostClient();

const user = ref({
    firstName: '',
    lastName: '',
    email: '',
    confirm_password: '',
    password: '',
    termsAccepted: false,
    anotherCheckbox: false
});

const errorMessage = ref('');
const successMessage = ref('');

const handleSubmit = async (event) => {
    event.preventDefault()
    errorMessage.value = "";

    let {email} = user.value;
    if (!email) {
        errorMessage.value = 'You need to inform your email to receive the verification link.';
        return;
    }

    const {error} = await nhost.auth.resetPassword({email, options: { redirectTo: 'http://localhost:8080/changePassword' }});
    successMessage.value = '';
    if (!error) {
        successMessage.value = 'Please check your mailbox and follow the link to reset your password.';
    }

}


const handleSignOut = () => {
    signOut();
}

onMounted(() => {
    handleSignOut()
})

</script>

<style>
.signup-container {
    background-image: url("/src/assets/bg.jpg");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.signup-content {
    max-width: 400px;
    margin-left: 100px;
}

@media only screen and (max-width: 600px) {
    .signup-content {
        margin-left: 0px!important;
        margin: 15px!important;
    }
}
</style>