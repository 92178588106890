<template>
    <div class="d-flex flex-column">
        <div class="card battle-card text-start" :style="`min-height: ${minHeight}px`">
            <span class="fw-bold text-white leaders-card subtitle ps-4 pb-2 pt-2">List of participants</span>
            <table class="text-white w-100">
                <th class="ps-4 pe-4">Name</th>
                <th class="text-end ps-4 pe-4">Points</th>
                <tr class="ps-4 pe-4" v-for="(leader, index) in leaders" :key="index">
                    <td class="ps-4 pe-4">
                        <img class="rounded-5 me-1" width="25" height="25" :src="getAvatarImage(leader.image)">
                        {{ leader.name }} {{ index + 1 }}
                        <img v-if="index === 0" :src="prizeImage">
                    </td>
                    <td class="ps-4 pe-4 text-end">{{ leader.points }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref, defineProps} from "vue";
import {getAvatarImage} from "@/helpers/profileHelpers";
import {getLeaderBoardRanking} from "@/helpers/challengesHelpers";
import {useNhostClient} from "@nhost/vue";

const prizeImage = require('@/assets/Prize.png');
const leaders = ref([]);
const {nhost} = useNhostClient();

defineProps({
    minHeight: {type: Number, default: 250}
})

onMounted(async () => {
    leaders.value = await getLeaderBoardRanking(nhost);
})
</script>

<style scoped>
th {
    background: #0B1B18;
}

tr td {
    padding: 8px;
}

tr {
    border-bottom: 0.4px solid #5a5a5a;
}
</style>