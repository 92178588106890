<template>
    <PageContent profile-active>
        <div class="d-flex flex-column w-50">
            <div class="text-center">
                <span class="fs-5"><b>Settings</b></span>
                <div>
                    <img class="rounded-5" v-if="avatarImage" :src="avatarImage"/><br>
                    <br>
                    <b>
                        {{user?.metadata?.firstName}}
                        {{user?.metadata?.lastName}}
                    </b>
                    <br>
                    {{user?.email}}
                </div>
            </div>
            <div class="mt-5">
                <div v-if="errorMessage" class="alert alert-danger" role="alert">
                    {{errorMessage}}
                </div>
                <div v-if="warningMessage" class="alert alert-warning" role="alert">
                    {{warningMessage}}
                </div>
                <div v-if="successMessage" class="alert alert-success" role="alert">
                    {{successMessage}}
                </div>
                <hr>
                <div class="menu-option mb-3">
                    <div class="d-flex justify-content-between" @click="editEmail = !editEmail">
                        <div class="ms-3">Email</div>
                        <div>
                            <i v-if="!editEmail" class="bi bi-chevron-right"></i>
                            <i v-else class="bi bi-chevron-down"></i>
                        </div>
                    </div>
                    <div v-show="editEmail">
                        <span class="text-start d-flex m-3">
                            If you click to change your user email, you will receive an email to update the access email.
                        </span>
                        <div class="m-3">
                            <text-input label="Add your new email" placeholder="New email" v-model="newUserEmail"/>
                        </div>
                        <div class="d-flex justify-content-center w-100">
                            <div class="w-50">
                                <action-button bootstrap-button-type="btn-outline-primary" v-show="editEmail" text-content="Change login Email" @click="handleChangeEmail"></action-button>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
                <hr>
                <div class="menu-option mb-3">
                    <div class="d-flex justify-content-between" @click="editPassword = !editPassword">
                        <div class="ms-3">Password</div>
                        <div><i class="bi bi-chevron-right"></i></div>
                    </div>
                    <div v-show="editPassword">
                        <PasswordInput placeholder="Password" v-model="userPassword"></PasswordInput>
                        <PasswordInput placeholder="Confirm Password" v-model="userPasswordConfirm"></PasswordInput>
                        <div class="d-flex justify-content-center w-100">
                            <div class="w-50">
                                <ActionButton bootstrap-button-type="btn-outline-primary" text-content="Change password" @click="handlePasswordChange"></ActionButton>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
                <hr>
                <div class="menu-option mb-3">
                    <div class="d-flex justify-content-between" @click="showLogout = !showLogout">
                        <div class="ms-3">Sign out</div>
                        <div>
                            <i v-if="!showLogout" class="bi bi-chevron-right"></i>
                            <i v-if="showLogout" class="bi bi-chevron-down"></i>
                        </div>
                    </div>
                    <button v-if="showLogout" @click="handleSignOut" type="button" class="btn btn-primary m-3" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        Sign Out
                    </button>
                </div>
                <hr>
            </div>
        </div>
    </PageContent>
</template>

<script setup>
import {useChangeEmail, useNhostClient, useSignOut, useUserData} from '@nhost/vue'
import {onMounted, ref} from 'vue'
import PageContent from "@/components/PageContent";
import PasswordInput from "@/components/PasswordInput";
import ActionButton from "@/components/ActionButton";
import router from "@/router";
import TextInput from "@/components/TextInput";
import {getAvatarImage} from "@/helpers/profileHelpers";

const { signOut } = useSignOut()

// highlight-end
const user = ref(useUserData());
// highlight-start
const { nhost } = useNhostClient()

const showLogout = ref(false);

const editEmail = ref(false);
const editPassword = ref(false);
const userPassword = ref('');
const userPasswordConfirm = ref('');
const avatarImage = ref(null);
const { changeEmail } = useChangeEmail();
const newUserEmail = ref("");
const errorMessage = ref("");
const warningMessage = ref("");
const successMessage = ref("");

const handlePasswordChange = async () => {
    warningMessage.value = '';
    if (!userPassword.value || !userPasswordConfirm.value || userPasswordConfirm.value !== userPassword.value) {
            warningMessage.value = "You need to enter a password and confirm it; the confirmed password must match the provided password.";
            return;
    }

    let response = await nhost.auth.changePassword({newPassword: userPassword.value});
    let {error} = response;
    if (error) {
        errorMessage.value = error;
        return;
    }

    successMessage.value = "Password changed successfully.";
    editPassword.value = false;
}

onMounted(async () => {
    let avatarUrl = user?.value?.avatarUrl;
    if (avatarUrl) {
        avatarImage.value = await getAvatarImage(avatarUrl);
    }
})

const handleSignOut = () => {
    signOut();
    router.push('/login')
}

const handleChangeEmail = async (e) => {
    errorMessage.value = "";
    warningMessage.value = "";
    e.preventDefault()

    if (!newUserEmail.value) {
        errorMessage.value = 'You need to provide the new email for the change.';
        return;
    }

    let requestChangeEmail = await changeEmail(newUserEmail.value)
    let { error, isError, needsEmailVerification } = requestChangeEmail;
    if (isError) {
        errorMessage.value = error;
    } else {
        if (needsEmailVerification) {
            warningMessage.value = "Access your email to confirm the change.";
        }
        editEmail.value = false;
    }
}

</script>

<style>
.menu-option {
    cursor: pointer;
}

.modal-overlay {
    top: 0px;
    left: 0px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.29);
    text-align: center;
    display: flex;
    width: 100%;
    height: 100%;
}

.control-modal {
    margin-top: 40%;
}
</style>