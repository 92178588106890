<template>
    <div class="manage-container d-flex justify-content-center">
        <div class="w-100 z-2 d-flex flex-column justify-content-between">
            <TopProfileBar/>
<!--            <div class="ps-5 text-start"> <a href="#" @click="$router.push('/home')">Home</a> > <b>-->
<!--                <span class="text-white">-->
<!--                    Manage-->
<!--                </span>-->
<!--            </b></div>-->
            <div class="d-flex text-start ms-4 me-4 admin-container">
                <ManageRewards class="mobile-card" :max-height="200" @selectChallenge="handleSelectChallenge"></ManageRewards>
                <RewardDetail class="ms-4 mobile-card" :max-height="150" :reward="userChallenge"></RewardDetail>
            </div>
            <div class="d-flex justify-content-between m-4 admin-container">
                <InvitePeople class="mobile-card"></InvitePeople>
                <SubmissionsProgress class="ms-4 mobile-card" @viewFiles="handleViewFiles" :reward="userChallenge"></SubmissionsProgress>
            </div>
        </div>
        <FileViewer
            title="Submission"
            v-if="filesToReview.length > 0"
            :files="filesToReview"
            @close="filesToReview = []"
        />
    </div>
</template>

<script setup>
import TopProfileBar from "@/components/TopProfileBar";
import ManageRewards from "@/components/ManageRewards";
import RewardDetail from "@/components/RewardDetail";
import {ref} from "vue";
import InvitePeople from "@/components/InvitePeople";
import SubmissionsProgress from "@/components/SubmissionsProgress";
import FileViewer from "@/components/FileViewer";

const userChallenge = ref(null);
const filesToReview = ref([]);

const handleSelectChallenge = (challenge) => {
    userChallenge.value = challenge;
}

const handleViewFiles = (files) => {
    console.log(files);
    filesToReview.value = files;
};

</script>

<style scoped>
.manage-container {
    overflow-x: hidden;
    background-image: url("/src/assets/bg.jpg");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Inter';
}

@media only screen and (max-width: 600px) {
    .admin-container {
        flex-direction: column;
    }

    .mobile-card {
        width: auto!important;
        margin: 10px!important;
    }
}
</style>