
<template>
    <div class="d-flex flex-column w-50">
        <div class="card battle-card text-start p-3" :style="`min-height: ${minHeight}px; max-height: ${maxHeight}px; overflow-y: auto`">
            <div class="text-center h-100 d-flex flex-column align-items-center justify-content-center  " v-if="!reward">
                <span class="fw-bold text-white">Submissions & Progress</span>
                <span class="text-white">Select a participants to see their submission & progress details</span>
            </div>
            <div class="text-start text-white" v-else>
                <span class="fw-bold">Submissions & Progress</span><br>
                <div v-if="completeChallenge">
                    <span class="label-text">Challenge </span> {{completeChallenge.title}} <span class="level-info">Level {{completeChallenge.level}}</span>
                </div>
                <div class="text-start">
                    <div v-if="completeChallenge" class="d-flex align-content-center justify-content-between">
                        <div>
                            <div class="text-primary fw-bold">Progress</div>
                            <div class="text-white">{{completedTasks}}/{{tasks.length}}</div>
                        </div>
                        <div class="coin-icon">
                            <img width="20" height="20" src="@/assets/gold_coins.png">
                            {{completeChallenge.points}} Points
                        </div>
                    </div>
                    <ProgressBar class="m-1" :value="tasksProgressCount"/>
                </div>
                <ul v-if="challengeTasks.length > 0" class="custom-list">
                    <li v-for="(item, index) in challengeTasks" :key="index">
                        <div class="d-flex justify-content-between">
                            <div>{{item.challenge_task.title}}</div>
                            <div>
                                <action-button bootstrapButtonType="btn-secondary" text-content="View Submission" @click="handleViewSubmission(item)"></action-button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, defineProps, ref, watchEffect, defineEmits} from "vue";
import {useNhostClient} from "@nhost/vue";
import {getCompleteChallengeById, getUserChallengeTasks} from "@/helpers/challengesHelpers";
import ProgressBar from "@/components/ProgressBar";
import ActionButton from "@/components/ActionButton";
import {getImageFileFromStorage} from "@/helpers/fileHelpers";

const props = defineProps({
    reward: {type: Object, default: null},
    minHeight: {type: Number, default: 320},
    maxHeight: {type: Number, default: 250}
})

const emit = defineEmits(['viewFiles']);

const {nhost} = useNhostClient();
const challengeTasks = ref([]);
const completeChallenge = ref(null);

const tasks = computed(() => challengeTasks.value ?? []);
const completedTasks = computed(() => tasks.value.filter((item) => item.completed).length ?? 0)

const tasksProgressCount = computed(() => {
    const total = (tasks.value?.length ?? 0);
    return (completedTasks.value * 100) / total;
})

const updateChalengeTasks = async () => {
    if (props.reward) {
        challengeTasks.value = await getUserChallengeTasks(props.reward.user.id, props.reward.challenge.id, nhost);
    }
}
const updateCompleteChallenge = async () => {
    if (props.reward) {
        completeChallenge.value = await getCompleteChallengeById(props.reward.challenge.id, nhost);
    }
}

const handleViewSubmission = async (item) => {
    let title = item?.challenge_task?.title;
    let files = [];
    for (let fileId of JSON.parse(item.solved_data)) {
        let fileUrl = await getImageFileFromStorage(nhost, fileId);
        files.push({
            title,
            fileUrl
        });
    }
    emit('viewFiles', files)
}

watchEffect(() => {
    console.log(props.reward);
    updateChalengeTasks();
    updateCompleteChallenge();
})
</script>
<style scoped>
th {
    background: #0B1B18;
}

tr td {
    padding: 8px;
}

tr {
    border-bottom: 0.4px solid #5a5a5a;
}

.label-text {
    color: #BFBFBF;
}

.level-info {
    display: inline-flex;
    margin-top: 2px;
    padding: 4px 6px;
    border-radius: 4px;
    background: #133D35;
    max-width: fit-content;
}
</style>