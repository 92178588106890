import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from "@/pages/LoginPage";
import SignUpPage from "@/pages/SignUpPage";
import CodeConfirmPage from "@/pages/CodeConfirmPage"
import HomePage from "@/pages/HomePage"
import LeaderboardPage from "@/pages/LeaderboardPage"
import ChallengeDetails from "@/pages/ChallengeDetails"
import ProfilePage from "@/pages/ProfilePage";
import ResetPasswordPage from "@/pages/ResetPasswordPage";
import ChangePasswordPage from "@/pages/ChangePasswordPage";
import DashboardPage from "@/pages/DashboardPage";
import AdminManagePage from "@/pages/AdminManagePage";

const routes = [
    {
        path: '',
        component: LoginPage,
    },
    {
        path: '/',
        name: 'Login',
        component: LoginPage,
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
    },
    {
        path: '/home',
        name: 'Home',
        component: HomePage,
    },
    {
        path: '/leaderboards',
        name: 'Leaderboard',
        component: LeaderboardPage,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: DashboardPage,
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUpPage,
    },
    {
        path: '/resetPassword',
        name: 'ResetPassword',
        component: ResetPasswordPage
    },
    {
        path: '/changePassword',
        name: 'ChangePassword',
        component: ChangePasswordPage
    },
    {
        path: '/profile',
        name: 'Profile',
        component: ProfilePage,
    },
    {
        path: '/verification',
        name: 'Verification',
        component: CodeConfirmPage,
    },
    {
        path: '/challenge_details/:id',
        name: 'ChallengeDetails',
        component: ChallengeDetails,
    },
    {
        path: '/admin_page',
        name: 'AdminManagePage',
        component: AdminManagePage,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;