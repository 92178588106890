<template>
    <div class="leaderboard-container d-flex justify-content-center">
        <div class="w-100 z-2 d-flex flex-column justify-content-between">
            <TopProfileBar/>
<!--            <div class="ps-5 text-start"> <a href="#" @click="$router.push('/home')">Home</a> > <b>-->
<!--                <span class="text-white">-->
<!--                    Leaderboards-->
<!--                </span>-->
<!--            </b></div>-->
            <div class="mobile-view">
                <div class="d-flex flex-row justify-content-between ms-4 me-4 container-cards-mobile">
                    <div
                        class="card battle-card reward-card"
                        v-bind:class="{'locked-reward': reward.locked}"
                        v-for="(reward, index) in rewards" :key="index"
                    >
                        <div class="row text-start">
                            <img class="currency-icon" v-if="!reward.locked" src="@/assets/dollar.svg">
                            <img class="locked-icon" v-if="reward.locked" src="@/assets/lock.svg">
                            <div class="col">
                                <span class="subtitle fw-bold">Reward</span>
                            </div>
                            <div class="col" v-if="!reward.locked">
                                <img src="@/assets/Time.png">
                                Time left <span class="fw-bold">01 day 16 hrs</span>
                            </div>
                        </div>
                        <div class="text-start level-info" v-bind:class="{'level-locked-info': reward.locked}">
                            Level {{reward.level}}
                        </div>
                        <div class="text-start fs-1" v-bind:class="{'cash-prize': !reward.locked}">
                            <div v-if="reward.locked">
                                Locked
                                <div class="fs-6">
                                    Reward of {{reward.cashPrize}}$
                                </div>
                            </div>
                            <div class="text-primary" v-else>
                                {{reward.cashPrize}}$
                            </div>
                        </div>
                        <div v-if="!reward.locked" class="text-start">
                            100 points <span class="text-secondary">50 more till next level</span>
                            <div class="progress w-75" style="height: 8px">
                                <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div v-if="!reward.locked" class="text-start">
                            <img src="@/assets/People.png">
                            Number of Participants: <span class="text-white fw-bold">80</span>
                        </div>
                    </div>
                </div>
                <LeaderboardComponent class="m-4"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import TopProfileBar from "@/components/TopProfileBar";
import LeaderboardComponent from "@/components/LeaderboardComponent";

const rewards = ref([
    {
        timeLeft: 40,
        level: 1,
        cashPrize: 50,
        pointsToEarn: 100,
        participants: 80,
        locked: false
    },
    {
        timeLeft: 40,
        level: 2,
        cashPrize: 150,
        pointsToEarn: 100,
        participants: 80,
        locked: true
    },
    {
        timeLeft: 40,
        level: 3,
        cashPrize: 300,
        pointsToEarn: 100,
        participants: 80,
        locked: true
    }
])

</script>

<style scoped>
.leaderboard-container {
    background-image: url("/src/assets/bg.jpg");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Inter';
}

.level-info {
    display: inline-flex;
    margin-top: 2px;
    padding: 4px 6px;
    border-radius: 4px;
    background: #111715;
    max-width: fit-content;
}

.level-locked-info {
    background-color: #383849;
}

.cash-prize {
    font-weight: 700;
}

.reward-card {
    padding: 10px;
    margin-right: 2px;
    width: 32%;
    height: 232px;
    color: #F5F5F5;
    overflow-x: auto;
    border-radius: 16px;
    border: 1px solid #36D4B5;
    background: radial-gradient(128.15% 128.15% at 61.18% 108.7%, #0B1B18 0%, #112E28 100%, #112E28 100%);
}

.currency-icon {
    position: absolute;
    width: 120px;
    height: auto;
    flex-shrink: 0;
    z-index: 0;
    right: -35px;
    bottom: 35px;
}

.locked-icon {
    position: absolute;
    width: auto;
    height: 180px;
    flex-shrink: 0;
    z-index: 0;
    right: -51px;
    bottom: 25px;
}

.locked-reward {
    border-radius: 16px;
    background: radial-gradient(128.15% 128.15% at 61.18% 108.7%, #111715 0%, #1E2826 100%)!important;
    border: none!important;
}

/* WebKit (Chrome, Safari) */
::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1); /* Set the color of the thumb */
    border-radius: 4px; /* Optional: Round the corners of the thumb */
}

/* Firefox */
body {
    scrollbar-width: thin; /* Set the width of the scrollbar */
}

body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1); /* Set the color of the thumb */
}

.card {
    transition: transform 0.3s ease-out;
    cursor: pointer;
}

/* Define the hover effect */
.card:hover {
    transform: translateY(-20px); /* Adjust the value as needed */
    border: solid 2px white;
}

@media only screen and (max-width: 600px) {
    .mobile-view {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around!important;
    }

    .container-cards-mobile {
        overflow-x: auto;
    }

    .reward-card {
        min-width: 350px;
        overflow: hidden;
        margin: 10px;
    }
}
</style>