
export const getAllChallenges = async (nhostClient) => {
    return await nhostClient.graphql.request(`
    query {
      challenges {
        id
        title
        description
        card_image
        character_image_id
        order
        points
      }
    }
  `)
}

export const getCompleteChallengeById = async (id = '', nhostClient) => {
    let result = await nhostClient.graphql.request(`
    query {
      challenges(where: {id: {_eq: "${id}"}}) {
        id
        title
        description
        card_image
        order
        points
        character_image_id,
        level
        challenge_tasks {
          id,
          title,
          description,
          action
        }
      }
    }
  `)

   return result.data?.challenges[0] ?? null;
}

export const startChallenge = async(userId = null, challengeId= null, challengeTasks=null, nhostClient) => {
    if (!challengeTasks) {
        return {
            status: 'error',
            message: 'You need to inform tasks.'
        }
    }

    await nhostClient.graphql.request(`
        mutation userChallenges($userId: uuid!, $challengeId: uuid!) {
                insert_user_challenges_one(object: {user_id: $userId, challenge_id: $challengeId}) {
                id
            }
        }`,
{
            "userId": userId,
            "challengeId": challengeId
        }
    );

    // Create user tasks
    let tasks = [];
    for (let task of challengeTasks) {
        tasks.push({
            user_id: userId,
            task_id: task.id,
            start_date: new Date()
        })
    }

    let {tasksError} = await nhostClient.graphql.request(`
    mutation userTasksProgress($objects: [user_tasks_progress_insert_input!]!) {
        insert_user_tasks_progress(objects: $objects) {
            affected_rows
        }
    }
    `,
{
            "objects": tasks
        }
    );

    if (tasksError) {
        return {
            status: 'error',
            message: tasksError
        }
    }

    return {
        status: 'success'
    }
}

export const getUserChallengeTasks = async(userId = null, challengeId = null, nhostClient) => {
    await nhostClient.auth.refreshSession();
    let {data, error} = await nhostClient.graphql.request(`
            query($userId: uuid!, $challengeId: uuid!) {
              user_tasks_progress(
                where: {
                  user_id: {_eq: $userId},
                  challenge_task: {
                    challenge_id: {_eq: $challengeId}
                  }
                }
              ) {
                id
                user_id
                task_id,
                completed,
                solved_data
                challenge_task {
                  id,
                  title,
                  description,
                  action
                }
              }
            }
        `
        ,
        {
            "userId": userId,
            "challengeId": challengeId
        }
    )

    if (error) {
        return {
            status: 'error',
            message: error
        }
    }

    return data.user_tasks_progress;
}

export const finishTask = async(taskId = null, metadata = [], nhostClient) => {
    await nhostClient.auth.refreshSession();
    await nhostClient.graphql.request(`
        mutation UpdateTasksProgress($taskId: uuid!, $metadata: String!) {
          update_user_tasks_progress(
            where: {task_id: {_eq: $taskId}},
            _set: {completed: true, solved_data: $metadata}
          ) {
            affected_rows
          }
        }
    `,{
        taskId,
        metadata
    })

    return true;
}

export const finishChallengeAndGivePoints = async(userId = null, challengeId = null, points = 0, nhostClient) => {
    let finishDate = new Date();
    await nhostClient.graphql.request(`
        mutation userChallenges($userId: uuid!, $challengeId: uuid!, $points: Int!, $finishDate: timestamptz!) {
            update_user_challenges(
                where: {user_id: {_eq: $userId}, challenge_id: {_eq: $challengeId}},
                _set: {completed: true, end_date: $finishDate, points: $points}
            ) {
                affected_rows
            }
        }`,
        {
            userId,
            challengeId,
            points,
            finishDate
        }
    );
}

export const getLeaderBoardRanking = async (nhostClient) => {
    await nhostClient.auth.refreshSession();
    let response = await nhostClient.graphql.request(`
    query UserChallenges($completed: Boolean!) {
      user_challenges(where: {completed: {_eq: $completed}}) {
        points
        user {
          id
          avatarUrl
          metadata
        }
      }
    }
    `,{
        completed: true
    });

    let groupResult = {};
    if (response?.data) {
        let {user_challenges} = response.data;
        for (let item of user_challenges) {
            if (groupResult[item.user.id]) {
                groupResult[item.user.id]['points'] += item.points;
            } else {
                let {firstName, lastName} = item.user.metadata;
                groupResult[item.user.id] = {
                    points: item.points,
                    image: item.user.avatarUrl,
                    name: `${firstName} ${lastName}`
                }
            }
        }
    }

    return Object.values(groupResult) ?? [];
}

export const getChallengesActivity = async (nhostClient) => {
    await nhostClient.auth.refreshSession();
    let {data} = await nhostClient.graphql.request(`
        query ListChallengesProgress {
          user_tasks_progress(where: {completed: {_eq: true}}, order_by: {end_date: desc}, limit: 100) {
            user {
              metadata
              user_challenge {
                challenge {
                  title
                }
              }
            }
            solved_data
            challenge_task {
              title
            }
          }
        }
    `)

    return data?.user_tasks_progress ?? [];
}

export const getCompletedChallenges = async (nhostClient) => {
    await nhostClient.auth.refreshSession();
    let {data} = await nhostClient.graphql.request(`
        query ListCompletedChallenges {
          user_challenges(where: {completed: {_eq: true}}, order_by: {end_date: desc}, limit: 100) {
            user {
              id
              metadata
            }
            challenge {
              id
              title
              level
            }
          }
        }
    `)

    return data?.user_challenges ?? [];
}

export const getChallengesMetrics = async (nhostClient) => {
    await nhostClient.auth.refreshSession();
    let {data} = await nhostClient.graphql.request(`
        query ChallengesMetrics {
          users {
            id
          }
          challenges {
            id
          }
          user_tasks_progress(where: {completed: {_eq: true}}) {
            id
          }
        }
    `)
    return data ?? [];
}

export const getUserList = async (nhostClient) => {
    await nhostClient.auth.refreshSession();
    let {data} = await nhostClient.graphql.request(`
        query UserList {
          users {
            id
            metadata
            avatarUrl
          }
        }
    `)
    return data?.users ?? [];
}

