<template>
    <div class="d-flex signup-container align-items-center">
        <div class="signup-content">
            <div class="row text-start mb-2 text-white text-center">
                <div class="d-flex justify-content-center align-items-center">
                    <SmallLogo/>
                </div>
                <div class="mt-3">
                    <h4>Create a new account</h4>
                    <span>Welcome! 👋</span>
                </div>
                <br>
            </div>
            <div v-if="!needsEmailVerification">
                <div class="row">
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                    <TextInputLogin
                        v-model="user.firstName"
                        placeholder="Your first name"
                    />
                    <TextInputLogin
                        v-model="user.lastName"
                        placeholder="Your last name"
                    />
                    <EmailLoginInput
                        v-model="user.email"
                        placeholder="name@email.com"
                    />
                    <PasswordInputLogin
                        v-model="user.password"
                        placeholder="Enter your password"
                    />
                    <PasswordInputLogin
                        v-model="user.confirm_password"
                        placeholder="Confirm password"
                    />
                </div>
                <div class="row mt-3">
                    <ActionButton @click="handleSubmit" text-content="Sign up"/>
                </div>
                <div class="row mt-2">
                    <div>
                        <span class="text-secondary">Already have an account? </span>
                        <a
                            class="link-underline link-underline-opacity-0"
                            href="#"
                            @click="router.push('/login')"
                        >Log in</a>
                    </div>
                </div>
            </div>
            <div class="text-start" v-else>
                <p class="text-success">
                    Please check your mailbox and follow the verification link to verify your email.
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import ActionButton from "@/components/ActionButton";
import {useSignOut, useSignUpEmailPassword} from '@nhost/vue'
const { signUpEmailPassword, needsEmailVerification } = useSignUpEmailPassword()

import {onMounted, ref} from "vue";
import router from "@/router";
import PasswordInputLogin from "@/components/PasswordInputLogin";
import TextInputLogin from "@/components/TextInputLogin";
import EmailLoginInput from "@/components/EmailLoginInput";
import SmallLogo from "@/components/SmallLogo";
const { signOut } = useSignOut()

const user = ref({
    firstName: '',
    lastName: '',
    email: '',
    confirm_password: '',
    password: '',
    termsAccepted: false,
    anotherCheckbox: false
});

const errorMessage = ref('');

const handleSubmit = async (event) => {
    event.preventDefault()
    errorMessage.value = "";

    let {password, confirm_password, firstName, lastName} = user.value;
    if (!password || !confirm_password) {
        errorMessage.value = 'You need to create a password and confirm it.';
        return;
    } else if(password !== confirm_password) {
        errorMessage.value = "The password confirmation does not match the provided password.";
        return;
    }

    const { isSuccess, isError, error } = await signUpEmailPassword(user.value.email, user.value.password, {
        metadata: { firstName, lastName }
    })
    if (isSuccess)
        router.push('/Home')
    if (isError) {
        errorMessage.value = error.message;
    }
}


const handleSignOut = () => {
    signOut();
}

onMounted(() => {
    handleSignOut()
})

</script>

<style>
.signup-container {
    background-image: url("/src/assets/bg.jpg");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.signup-content {
    max-width: 400px;
    margin-left: 100px;
}

@media only screen and (max-width: 600px) {
    .signup-content {
        margin-left: 0px!important;
        margin: 15px!important;
    }
}
</style>