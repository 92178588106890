<template>
    <div class="leaderboard-container d-flex justify-content-center">
        <div class="w-100 z-2 d-flex flex-column justify-content-between">
            <TopProfileBar/>
<!--            <div class="ps-5 text-start"> <a href="#" @click="$router.push('/home')">Home</a> > <b>-->
<!--                <span class="text-white">-->
<!--                    Dashboard-->
<!--                </span>-->
<!--            </b></div>-->
            <div class="d-flex flex-row justify-content-between text-start ms-4 me-4 metrics-container">
                <div class="card battle-card p-3 w-100" v-bind:class="{'ms-2': (index !== 0)}" v-for="(metric, index) in metrics" :key="index">
                    <div class="text-white fw-bold">
                        <img :src="metric.imageName" height="25">
                        {{metric.title}}
                    </div>
                    <div class="text-primary title">{{metric.value}}</div>
                    <div></div>
                </div>
            </div>
            <div class="d-flex justify-content-between m-4 dashboard-container">
                <ChallengesUpdateComponent class="w-75 challenges-update" min-height="400"/>
                <LeaderboardComponent class="ms-2 m-50 w-50 leaderboard-content" min-height="400"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import TopProfileBar from "@/components/TopProfileBar";
import LeaderboardComponent from "@/components/LeaderboardComponent";
import ChallengesUpdateComponent from "@/components/ChallengesUpdateComponent";
import {getChallengesMetrics} from "@/helpers/challengesHelpers";
import {useNhostClient} from "@nhost/vue";
import {onMounted, ref} from "vue";

const {nhost} = useNhostClient();
const metrics = ref(null);

const updateMetrics = async () => {
    let data = await getChallengesMetrics(nhost);

    let totalParticipants = data?.users?.length ?? 0;
    let totalChallenges = data?.challenges?.length ?? 0;
    let totalSubmissions = data?.user_tasks_progress?.length ?? 0;

    let engagementLevel = "75%";

    metrics.value = [
        {
            title: 'Participants',
            value: totalParticipants,
            imageName: require("@/assets/UsersThree.svg")
        },
        {
            title: 'Challenges',
            value: totalChallenges,
            imageName: require("@/assets/Target.svg")
        },
        {
            title: 'Submissions',
            value: totalSubmissions,
            imageName: require("@/assets/PaperPlaneRight.svg")
        },
        {
            title: 'Engagement Level',
            value: engagementLevel,
            imageName: require("@/assets/HandPointing.svg")
        }
    ]
}

onMounted(() => {
    updateMetrics();
})

</script>

<style scoped>
.leaderboard-container {
    overflow-x: hidden;
    background-image: url("/src/assets/bg.jpg");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Inter';
}

.level-info {
    display: inline-flex;
    margin-top: 2px;
    padding: 4px 6px;
    border-radius: 4px;
    background: #111715;
    max-width: fit-content;
}

.level-locked-info {
    background-color: #383849;
}

.cash-prize {
    font-weight: 700;
}

.reward-card {
    padding: 10px;
    margin-right: 2px;
    width: 32%;
    height: 232px;
    color: #F5F5F5;
    overflow-x: auto;
    border-radius: 16px;
    border: 1px solid #36D4B5;
    background: radial-gradient(128.15% 128.15% at 61.18% 108.7%, #0B1B18 0%, #112E28 100%, #112E28 100%);
}

.currency-icon {
    position: absolute;
    width: 120px;
    height: auto;
    flex-shrink: 0;
    z-index: 0;
    right: -35px;
    bottom: 35px;
}

.locked-icon {
    position: absolute;
    width: auto;
    height: 180px;
    flex-shrink: 0;
    z-index: 0;
    right: -51px;
    bottom: 25px;
}

.locked-reward {
    border-radius: 16px;
    background: radial-gradient(128.15% 128.15% at 61.18% 108.7%, #111715 0%, #1E2826 100%)!important;
    border: none!important;
}

/* WebKit (Chrome, Safari) */
::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1); /* Set the color of the thumb */
    border-radius: 4px; /* Optional: Round the corners of the thumb */
}

/* Firefox */
body {
    scrollbar-width: thin; /* Set the width of the scrollbar */
}

body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1); /* Set the color of the thumb */
}

.card {
    transition: transform 0.3s ease-out;
    cursor: pointer;
}

/* Define the hover effect */
.card:hover {
    transform: translateY(-20px); /* Adjust the value as needed */
    border: solid 2px white;
}

@media only screen and (max-width: 600px) {
    .metrics-container {
        display: flex!important;
        flex-wrap: wrap!important;
    }

    .battle-card {
        width: 45%!important;
        margin: 5px!important;

    }

    .dashboard-container {
        flex-wrap: wrap!important;
        margin-bottom: 5%!important;
    }

    .leaderboard-content {
        margin-top: 20px!important;
        margin-left: 0px!important;
        width: 100%!important;
    }

    .challenges-update {
        width: 100%!important;
    }

}
</style>