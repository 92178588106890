<template>
    <div class="d-flex login-container align-items-center">
        <div class="login-content">
            <div class="row text-start mb-2 text-white text-center">
                <div class="d-flex justify-content-center align-items-center">
                    <SmallLogo/>
                </div>
                <div class="mt-3">
                    <h4>Login to your account</h4>
                    <span>Welcome back! 👋</span>
                </div>
                <br>
            </div>
            <div class="row">
                <div>
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                    <div v-if="needsEmailVerification" class="alert alert-warning" role="alert">
                        Your email is not yet verified. Please check your mailbox and
                        follow the verification link to finish registration.
                    </div>
                </div>
                <div>
                    <EmailLoginInput
                        v-model="user.email"
                        placeholder="Enter your email"
                    />
                    <PasswordInputLogin
                        v-model="user.password"
                        placeholder="Enter your password"
                    />
                </div>
            </div>
            <div class="row text-start">
                <a
                    href="#"
                    class="link-underline link-underline-opacity-0"
                    @click="router.push('resetPassword')"
                >
                    <b>Forgot password?</b>
                </a>
            </div>
            <div class="row mt-3">
                <ActionButton class="w-100" @click="handleSubmit" text-content="Login"/>
            </div>
            <div class="row mt-2">
                <div>
                    <span class="text-secondary">Don't have an account? </span>
                    <a
                        class="link-underline link-underline-opacity-0"
                        href="#"
                        @click="router.push('/signup')"
                    >Create an account now</a>
                </div>
            </div>
            <hr>
        </div>
    </div>
</template>

<script setup>
import ActionButton from "@/components/ActionButton";
import {useNhostClient, useSignInEmailPassword} from '@nhost/vue'
import {onMounted, ref} from "vue";
import router from "@/router";
import EmailLoginInput from "@/components/EmailLoginInput";
import PasswordInputLogin from "@/components/PasswordInputLogin";
import SmallLogo from "@/components/SmallLogo";
const { signInEmailPassword, needsEmailVerification } = useSignInEmailPassword()

const {nhost} = useNhostClient();

const user = ref({
    email: '',
    password: ''
})

const errorMessage = ref("");

const handleSubmit = async (event) => {
    event.preventDefault()
    const {email, password} = user.value;
    const { isSuccess, isError, error } = await signInEmailPassword(email, password)
    if (isSuccess)
        router.push('/home')

    if (isError) {
        errorMessage.value = error.message;
    }
}

onMounted(async () => {
    await nhost.auth.refreshSession();
    if (nhost.auth.isAuthenticated()) {
        router.push('/home');
    }
})

</script>

<style scoped>
.login-container {
    background-image: url("/src/assets/bg.jpg");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.login-content {
    max-width: 400px;
    margin-left: 100px;
}

@media only screen and (max-width: 600px) {
    .login-content {
        margin: 15px;
    }
}

</style>