<template>
    <div class="d-flex flex-column w-50">
        <div class="card battle-card text-start" :style="`min-height: ${minHeight}px; max-height: ${maxHeight}px; overflow-y: auto`">
            <span class="fw-bold text-white leaders-card subtitle ps-3 pt-3">Send Invite</span>
            <div class="ps-3 pe-3">
                <EmailInput v-model="userEmail"></EmailInput>
                <ActionButton @click="handleSendInvite" class="email-btn" text-content="Send invite"></ActionButton>
                <span class="text-white fw-bold p-1" v-if="showEmailFeedback">E-mail sent</span>
            </div>
            <span class="text-white fw-bold ps-3 pt-3">Participants List</span>
            <table class="text-white w-100">
                <tr
                    class="ps-4 pe-4"
                    v-for="(user, index) in userList"
                    :key="index"
                >
                    <td>
                        <img height="30" width="30" class="rounded-5 p-1" v-show="getAvatarImage(user.avatarUrl)" :src="getAvatarImage(user.avatarUrl)"/>
                        <span>
                            {{user.metadata.firstName}} {{user.metadata.lastName}}
                        </span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import {useNhostClient} from "@nhost/vue";
import {getUserList} from "@/helpers/challengesHelpers";
import EmailInput from "@/components/EmailInput";
import ActionButton from "@/components/ActionButton";
import {getAvatarImage} from "@/helpers/profileHelpers";

defineProps({
    minHeight: {type: Number, default: 320},
    maxHeight: {type: Number, default: 200}
})

const {nhost} = useNhostClient();
const userList = ref([]);
const userEmail = ref('');
const showEmailFeedback = ref(false);

const handleSendInvite = async () => {
    if (userEmail.value) {
        await nhost.functions.call('send-email', {
            to: userEmail.value
        });
        showEmailFeedback.value = true;
        userEmail.value = '';
    }
}

const updateUserList = async () => {
    userList.value = await getUserList(nhost);
}

onMounted(() => {
    updateUserList();
})
</script>
<style scoped>
th {
    background: #0B1B18;
}

tr td {
    padding: 8px;
}

tr {
    border-bottom: 0.4px solid #5a5a5a;
    cursor: pointer;
}

.selected {
    background-color: #0B1B18;
}

.email-btn {
    position: absolute;
    right: 15px;
    margin-top: -43px;
}
</style>