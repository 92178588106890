<template>
    <div class="home-container d-flex justify-content-center">
        <div class="w-100 z-2 d-flex flex-column justify-content-between">
            <TopProfileBar/>
<!--            <div v-if="selectedChallenge" class="ms-4 d-flex align-items-start">-->
<!--                <a href="#" @click="$router.push('/leaderboards')">Leaderboards</a>-->
<!--                <a class="ms-1" href="#" @click="$router.push('/dashboard')">Dashboard</a>-->
<!--            </div>-->
            <div class="d-flex flex-column home-content">
                <div v-if="selectedChallenge" class="d-flex justify-content-between align-items-center container-description">
                    <div class="home-description-container text-start">
                        <span class="title">{{ selectedChallenge.title }}</span> <br>
                        <span class="subtitle text-white">{{selectedChallenge.description}}</span>
                    </div>
                    <div v-if="selectedChallenge.characterImageUrl">
                        <img class="character" :src="selectedChallenge.characterImageUrl" width="300" height="300">
                    </div>
                </div>
                <div class="ms-4 d-flex challenges-container">
                    <div
                        v-for="(challenge, index) in challenges"
                        :key="challenge.id"
                        @click="changeChallenge(index)"
                        @mouseover="previewChallenge(index)"
                        @mouseout="cancelPreviewChallenge()"
                    >
                        <div class="card challenge-card" @click="$router.push('/challenge_details/' + challenge.id)" :style="{ backgroundImage: 'url(' +challenge.card_image+ ')' }">
                            <div class="challenge-details d-flex flex-column justify-content-between">
                                <span>{{challenge.title}}</span>
                                <div class="progress" style="height: 8px">
                                    <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div class="coin-icon">
                                    <img width="20" height="20" src="@/assets/coins.png">
                                    {{challenge.points}} Points
                                </div>
                            </div>
                        </div>
                        <hr class="challenge-scroll">
                        <div class="challenge-dot"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useNhostClient} from '@nhost/vue'
import {onMounted, ref} from "vue";
import {getAllChallenges} from "@/helpers/challengesHelpers";
import {getImageFileFromStorage} from "@/helpers/fileHelpers";
import TopProfileBar from "@/components/TopProfileBar";
const { nhost } = useNhostClient()
const challenges = ref([]);

const selectedChallenge = ref(null);
const previousSelectedChallenge = ref(null);

onMounted(async () => {
    let result = await getAllChallenges(nhost);
    let data = result?.data?.challenges;
    if (data) {
        data = data.sort((a, b) => a.order - b.order);
    }
    challenges.value = data ?? [];
    changeChallenge(0)
})

const changeChallenge = async (challengeIndex) => {
    let challenge = challenges.value[challengeIndex];
    if (!challenge.characterImageUrl) {
        challenge.characterImageUrl = await getImageFileFromStorage(nhost, challenge.character_image_id)
    }
    selectedChallenge.value = challenge;
}

const previewChallenge = async (challengeIndex) => {
    previousSelectedChallenge.value = selectedChallenge.value;
    selectedChallenge.value = null;
    changeChallenge(challengeIndex);
}

const cancelPreviewChallenge = () => {
    selectedChallenge.value  = previousSelectedChallenge.value;
    previousSelectedChallenge.value = null;
}

</script>

<style scoped>
.home-description-container {
    margin-left: 60px;
    width: 292px;
    height: 53px;
    flex-shrink: 0;
}

.home-container {
    overflow: hidden;
    background-image: url("/src/assets/bg.jpg");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.challenge-card {
    display: flex;
    justify-content: end;
    width: 280px;
    height: 230px;
    margin-right: 20px;
    background-position: center;
    background-size: cover;
    border-radius: 16px;
    background-color: #1E2826;
    border-radius: 16px;
}

.challenges-container {
    overflow-x: auto;
    max-width: 100%;
    height: 305px;
    align-items: end;
}

.challenge-dot {
    font-size: 14px;
    color: black;
    position: relative;
    bottom: 18px;
    height: 15px;
    width: 15px;
    background-color: black;
    border-radius: 10px;
    border: solid 2px white;
}

.challenge-scroll {
    height: 2px;
    background-color: white;
    border: none;
    margin: 10px 0;
    opacity: 0.8;
}

.challenge-details {
    padding: 10px;
    text-align: start;
    height: 50%;
    color: white;
    font-family: 'Inter';
    font-weight: bold;
    border-radius: 0px 0px 16px 16px;
    background: linear-gradient(0deg, rgba(52, 52, 59, 0.75) 0%, rgba(52, 52, 59, 0.38) 70.49%);
}

.character {
    position: relative;
    right: 200px;
}

.card {
    transition: transform 0.3s ease-out;
    cursor: pointer;
}

/* Define the hover effect */
.card:hover {
    transform: translateY(-20px); /* Adjust the value as needed */
    border: solid 2px white;
}

.coin-icon {
    border-radius: 8px;
    background: rgba(85, 85, 85);
    display: inline-flex;
    height: 35px;
    width: 120px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    font-family: Roboto;
}

.container-description {
    height: 45%;
}

@media only screen and (max-width: 600px) {
    .home-container {
        background-position: center;
    }

    .container-description {
        align-items: flex-start;
        flex-direction: column;
        height: 60%;
    }

    .character {
        right: auto!important;
    }

    .home-description-container {
        height: 144px;
        margin-left: 0px!important;
    }

    .home-content {
        margin-bottom: 5%;
    }
}
</style>