<template>
    <div class="text-start mt-2 mb-2">
        <label class="fw-bold" for="control-input">
            {{label}}
        </label>
        <div class="input-group">
            <input
                class="form-control border-end-0"
                id="control-input"
                :type="showPassword ? 'text' : 'password'"
                aria-describedby="eye-addon1"
                :value="modelValue"
                :placeholder="placeholder"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <span
                class="input-group-text bg-transparent border-start-0"
                id="eye-addon1"
                @click="handleShowPassword"
            >
                <i v-if="!showPassword" class="bi bi-eye-slash"></i>
                <i v-else class="bi bi-eye"></i>
            </span>
        </div>
    </div>
</template>

<script setup>

import {defineProps, ref} from "vue";

defineProps({
    modelValue: String,
    label: String,
    placeholder: { type: String, default: '' }
});

const showPassword = ref(false);

const handleShowPassword = () => {
    showPassword.value = !showPassword.value;
}

</script>

<style scoped>
i {
    cursor: pointer;
}
</style>
