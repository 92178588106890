<template>
    <div class="ms-4 me-4 mb-4 d-flex cards-container">
        <div class="card battle-card w-50 h-50 challenge-details-card">
            <div class="empty-state d-flex flex-column align-items-center">
                <img src="@/assets/challenge_icon.png" width="50" height="40">
                <span class="p-2">Click below to Start the Challenge and see your tasks</span>
                <action-button
                    :loading="loading"
                    text-content="Start the Challenge"
                    @click="initiateChallenge"
                />
            </div>
        </div>
        <div class="card battle-card ms-2 w-50 h-50 challenge-details-card">
            <div class="empty-state d-flex flex-column align-items-center">
                <img src="@/assets/square_dot.png" width="50" height="40">
                <span class="p-2 fw-bold">No Task Selected</span>
                <span>Select any task to see the details here.</span>
            </div>
        </div>
    </div>
</template>
<script setup>
import {startChallenge} from "@/helpers/challengesHelpers";
import ActionButton from "@/components/ActionButton";
import {ref, defineEmits, defineProps} from "vue";
import {useNhostClient, useUserData} from "@nhost/vue";

const loading = ref(false);
const emit = defineEmits(['startChallenge']);
const user = useUserData();
const { nhost } = useNhostClient();

const props = defineProps({
    challengeId: String,
    challengeTasks: {type: Array, default: () => {return []}}
})

const initiateChallenge = async () => {
    loading.value = true;
    let response = await startChallenge(user.value.id, props.challengeId, props.challengeTasks, nhost);
    console.log('START CHALLENGE RESPONSE: ', response);
    loading.value = false;
    emit('startChallenge');
    window.location.reload();
}

</script>
<style scoped>
/* Define the hover effect */
.battle-card {
    border-radius: 16px;
    border: 1px solid #0C4A3D;
    background: radial-gradient(128.15% 128.15% at 61.18% 108.7%, #0B1B18 0%, #112E28 100%, #112E28 100%);
    overflow: hidden;
}

.challenge-details-card {
    min-height: 220px;
}

.empty-state {
    border: dashed 1px #5B5B5B;
    padding: 10px;
    margin: 25px;
    height: 90%;
    width: 90%;
    border-radius: 5px;
    color: white;
}


@media only screen and (max-width: 600px) {
    .cards-container {
        flex-wrap: wrap;
        flex-direction: column!important;
        width: 100%!important;
        margin: 0px!important;
    }

    .challenge-details-card {
        width: 94%!important;
        margin: 15px!important;
    }
}
</style>